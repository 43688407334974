// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
	--ion-color-primary: #4f607d;
	--ion-color-primary-rgb: 79,96,125;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #46546e;
	--ion-color-primary-tint: #61708a;

	--ion-color-secondary: #5260ff;
	--ion-color-secondary-rgb: 82,96,255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #4854e0;
	--ion-color-secondary-tint: #6370ff;

	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82,96,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

}

:root {
  --ion-background-color: #e2e2e4;
  --ion-background-color-rgb: 226,226,228;

  --ion-text-color: #212121;
  --ion-text-color-rgb: 33,33,33;

  --ion-color-step-50: #d8d8da;
  --ion-color-step-100: #cfcfd1;
  --ion-color-step-150: #c5c5c7;
  --ion-color-step-200: #bbbbbd;
  --ion-color-step-250: #b2b2b3;
  --ion-color-step-300: #a8a8aa;
  --ion-color-step-350: #9e9ea0;
  --ion-color-step-400: #959596;
  --ion-color-step-450: #8b8b8c;
  --ion-color-step-500: #828283;
  --ion-color-step-550: #787879;
  --ion-color-step-600: #6e6e6f;
  --ion-color-step-650: #656565;
  --ion-color-step-700: #5b5b5c;
  --ion-color-step-750: #515152;
  --ion-color-step-800: #484848;
  --ion-color-step-850: #3e3e3e;
  --ion-color-step-900: #343434;
  --ion-color-step-950: #2b2b2b;
}
